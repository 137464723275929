import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d0eb664"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "public_module" }
const _hoisted_2 = { class: "search_box w_1200" }

import Head from '../components/DealHead.vue'
import DealFoot from '@/components/DealFoot.vue'
import HeadImg from '@/view/user/components/HeadImg.vue'
import Car from '../components/Car.vue'


export default {
  setup(__props) {


return (_ctx, _cache) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(Head, { headTitle: "电子交易中心首页" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(HeadImg, {
          icon: "",
          text: "购物车"
        })
      ])
    ]),
    _createVNode(Car),
    _createVNode(DealFoot)
  ], 64))
}
}

}